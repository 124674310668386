/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-11 18:55:06
 * @Module: 转化人表单
 */
 <template>
  <div style="display:flex;justify-content: space-between;">
    <el-select style="width: 90%" filterable value-key="accountId" :value="value" placeholder="请选择转化人" @change="change" :clearable="clearable">
      <el-option v-for="item in accountLegalList" :key="item.accountId" :label="item.name" :value="item.accountId">
        <div style="display:flex;align-items:center;justify-content:space-between">
          <span :style="`color:${item.online?'#67C23A':'#ccc'}`">{{ item.name }}</span>
          <el-tag type="success" size="mini" class="pointer" v-if="item.online">在线</el-tag>
          <el-tag type="info" size="mini" class="pointer" v-else>离线</el-tag>
        </div>
      </el-option>
    </el-select>
		<el-button type="text" @click="getAccountLegalList" v-if="refresh">刷新</el-button>
  </div>

</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Boolean,
      default: true,
    },

  },
  data () {
    return {
      accountLegalList: [],
    };
  },
  mounted () {
    this.getAccountLegalList();
  },
  methods: {
    async getAccountLegalList () {
      const {
        status,
        data: { transformers },
      } = await this.$api.getCustomerFilterList();
      if (status === 200) {
        this.accountLegalList = transformers;
      }
    },
    change (res) {
      this.$emit("input", res);
    },
  },
};
</script>
 <style lang='scss' scoped>

</style>