/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-23 13:52:29
 * @Module: 主营业务
 */
 <template>
  <div>
    <el-select style="width:100%;" filterable value-key="businessId" :value="value" multiple placeholder="请选择主营业务" clearable @change="change">
      <el-option v-for="item in businessList" :key="item.businessId" :label="item.name" :value="item.businessId">
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px" @click.stop="()=>onDelete(item)">删除</span>
      </el-option>
    </el-select>
    <el-button type="text" icon="el-icon-circle-plus-outline" size="mini" style="float:right;" round @click="add">添加主营业务</el-button>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      businessList: [],
      loading: false
    };
  },

  mounted () {
    this.getBusinessList()
  },
  methods: {
    async getBusinessList () {
      this.loading = true;
      const { status, data } = await this.$api.getBusinessList()
      if (status == 200) {
        this.businessList = data
      }
      this.loading = false;
    },
    change (value) {
      this.$emit('input', value)
    },
    async add () {
      try {
        const { value: name } = await this.$prompt('请输入主营业务名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
        this.loading = true;
        const { status, info } = await this.$api.postBusiness({ name })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.getBusinessList();
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
      }
      this.loading = false;
    },
    async onDelete (res) {
      try {
        await this.$confirm(`此操作将永久删除该选项《${res.name}》, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.loading = true;
        const { status, info } = await this.$api.deleteBusiness({ businessId: res.businessId })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.getBusinessList()
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
        this.loading = false;
      } catch (error) {
        console.log(error)
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>