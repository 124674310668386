<template>
  <div class="box">
    <el-row :gutter="60">
      <el-col :span="12">
        <div class="left">
          <h1>销转进程（待完成）</h1>
          <div class="content">
            <div class="item" v-for="item in TrendList" :key="item.trendId">
              <p>编号：{{ item.customer.number }}</p>
              <p style="color: rgb(252, 202, 0)">
                待完成信息发送时间：{{
                  item.createdAt | timeFormat("yyyy-mm-dd hh:MM:ss")
                }}
              </p>
              <div class="btn pointer" @click="() => toList(item)">查看</div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="right">
          <h1>定时任务提醒</h1>
          <el-alert
            title="任务截止时间不足15分钟，黄色；任务已经到时间却没有完成，红色。"
            type="info"
          >
          </el-alert>
          <div class="content">
            <jobItem v-for="item in JobList" :key="item.jobId" :item="item" />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import jobItem from "@/views/Home/components/jobItem";
export default {
  components: { jobItem },
  data() {
    return {
      TrendList: [],
      JobList: [],
    };
  },
  mounted() {
    this.getTrendUnseen();
    this.getJobInit();
  },
  computed: {},
  methods: {
    toList({ customer: { customerId } }) {
      this.$router.push({ path: "/customerManagement", query: { customerId } });
    },
    async getTrendUnseen() {
      const { data, status } = await this.$api.getTrendUnseen();
      if (status === 200) this.TrendList = data;
    },
    async getJobInit() {
      const { data, status } = await this.$api.getJobInit();
      if (status === 200) this.JobList = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 16px;
  border-radius: 4px;
  .left,
  .right {
    font-weight: 400;
    font-size: 16px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    h1 {
      font-size: 24px;
      line-height: 1.5em;
      text-align: center;
      background: #409eff;
      color: #fff;
      line-height: 3em;
    }
    .content {
      min-height: 500px;
      padding: 20px 36px;

      .item {
        background: rgb(127, 131, 247);
        position: relative;
        padding: 16px;
        color: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        p {
          font-size: 16px;
          line-height: 1.5em;
        }
        .btn {
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
    }
  }
}
</style>
