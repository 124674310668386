/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-09 14:45:26
 * @Module: 应定时任务表单
 */
<template>
  <div>
    <el-dialog
      append-to-body
      :title="
        type == 'add'
          ? '添加定时任务'
          : type == 'revision'
          ? '修改定时任务'
          : ''
      "
      :visible.sync="dialogVisible"
      width="40%"
      @closed="closed"
    >
      <div style="padding: 20px">
        <el-form
          size="mini"
          label-position="top"
          label-width="120px"
          :model="form"
          ref="form"
        >
          <el-row :gutter="30">
            <el-col :span="24">
              <el-form-item
                label="定时任务内容"
                prop="content"
                :rules="{ required: true, message: '定时任务内容不能为空' }"
              >
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 12 }"
                  v-model="form.content"
                  placeholder="请输入定时任务内容"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="任务时间"
                prop="deadline"
                :rules="{ required: true, message: '任务时间不能为空' }"
              >
                <el-date-picker
                  style="width: 100%"
                  value-format="timestamp"
                  v-model="form.deadline"
                  type="datetime"
                  placeholder="选择任务时间"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submit" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 <script>
export default {
  components: {},
  data() {
    return {
      type: "add",
      dialogVisible: false,
      form: {
        content: "",
        deadline: null,
        customerId: null,
      },
    };
  },

  mounted() {},
  methods: {
    show({ type = "add", data }) {
      this.form.customerId = data.customerId;
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type;
      this.dialogVisible = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postReceivable();
        } else {
          return false;
        }
      });
    },
    closed() {
      this.form = {
        content: "",
        deadline: null,
      };
    },
    // 添加应定时任务
    async postReceivable() {
      const api = {
        revision: {
          func: this.$api.putReceivable,
          params: {
            ...this.form,
          },
        },
        add: {
          func: this.$api.postjob,
          params: {
            ...this.form,
          },
        },
      };
      const { status, data, info } = await api[this.type].func(
        api[this.type].params
      );
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.$emit("success", this.type);
        this.dialogVisible = false;
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
  },
};
</script>
 <style lang='scss' scoped>
</style>