/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 13:37:09
 * @Module: 线索管理
 */
 <template>
  <div>
    <div style="
        mine-height: 68px;
        background: #fff;
        dispaly: flex;
        align-items: center;
        padding: 0 16px;
        overflow: hidden;
      ">
      <searchForm :searchForm.sync="listParams" @submit="getCompanyList" />
    </div>
    <div style="
        padding: 16px;
        background: #fff;
        margin-top: 24px;
        border-radius: 4px;
        padding-top: 0px;
      ">
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 56px;
        " class="box">
        <div>线索列表</div>

        <span>
          <el-button-group>
            <el-button type="primary" size="small" icon="el-icon-plus" v-if="[1].includes(userInfo_role)" @click="add">新增</el-button>
          </el-button-group>
        </span>
      </div>
      <el-table size="mini" @sort-change="sortChange" :data="list" tooltip-effect="dark" style="width: 100%; margin-top: 10px" :row-class-name="tableRowClassName">
        <el-table-column label="编号" prop="number" show-overflow-tooltip width="90">
          <template slot-scope="scope">
            <div style="height: 4.5em; line-height: 4.5em">
              {{ scope.row.number || "--" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="客户类型" prop="type" show-overflow-tooltip width="70">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.type == 0">主播</el-tag>
            <el-tag size="mini" type="success" v-else-if="scope.row.type == 1">公会</el-tag>
            <el-tag size="mini" type="warning" v-else-if="scope.row.type == 2">其它</el-tag>
            <span v-else>--</span>
          </template>
        </el-table-column>

        <el-table-column label="电话" prop="phone" show-overflow-tooltip width="90">
          <template slot-scope="scope">{{ scope.row.phone || "--" }}</template>
        </el-table-column>
        <el-table-column label="微信" prop="weixin" show-overflow-tooltip width="90">
          <template slot-scope="scope">{{ scope.row.weixin || "--" }}</template>
        </el-table-column>
        <el-table-column label="来源" prop="source" show-overflow-tooltip width="120">
          <template slot-scope="scope">
            <div class="ellipsis">
              {{ scope.row.source || "--" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="填表人" prop="provider.name" show-overflow-tooltip width="90">
          <template slot-scope="scope">{{
            scope.row.provider.name || "--"
          }}</template>
        </el-table-column>
        <el-table-column label="客资详情" prop="detail" show-overflow-tooltip min-width="220">
          <template slot-scope="scope">
            <el-popover placement="top-start" title="客资详情" width="500"  trigger="hover" :content="scope.row.detail">
              <span class="ellipsis" slot="reference">
                {{ scope.row.detail || "--" }}
              </span>
            </el-popover>

          </template>
        </el-table-column>
        <el-table-column label="转化人" prop="transformer.name" show-overflow-tooltip width="90">
          <template slot-scope="scope">{{
            scope.row.transformer?.name || "--"
          }}</template>
        </el-table-column>

        <el-table-column label="聊天截图" prop="images" show-overflow-tooltip width="90">
          <template slot-scope="scope">
            <el-image class="pointer" v-if="scope.row.images" style="width: 3em; height: 4.5em" :src="images(scope.row)[0]" fit="fill" @click="() => imageDialog(images(scope.row), scope.row.number)"></el-image>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="销转进程" prop="trends" show-overflow-tooltip min-width="220">
          <template slot-scope="scope">
            <div v-if="trends(scope.row).content" class="trends ellipsis pointer" :class="
                !isMine(trends(scope.row).publisher.accountId) &&
                !trends(scope.row).seen
                  ? ''
                  : 'yidu'
              " @click="() => trendsDialog(scope.row)">
              {{ trends(scope.row).content }}
            </div>
            <el-button v-else-if="[1, 2].includes(userInfo_role)" type="text" size="mini" @click="() => trendsDialog(scope.row)">添加</el-button>
            <div v-else>--</div>
          </template>
        </el-table-column>

        <el-table-column label="状态" prop="state" show-overflow-tooltip width="70">
          <template slot-scope="scope">
            <el-tag size="mini" type="warning" v-if="scope.row.state == 0">未启动</el-tag>
            <el-tag size="mini" v-else-if="scope.row.state == 1">进行中</el-tag>
            <el-tag size="mini" type="success" v-else-if="scope.row.state == 2">已完成</el-tag>
            <el-tag size="mini" type="info" v-else-if="scope.row.state == 3">放弃</el-tag>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="定时任务" prop="jobs" show-overflow-tooltip width="90">
          <template slot-scope="scope">
            <div v-if="ajobs(scope.row.jobs)" class="jobs pointer" @click="() => jobsDialog(scope.row)">
              {{ scope.row.jobs | jobs }}
            </div>
            <el-button type="text" size="mini" v-else-if="[1, 2].includes(userInfo_role)" @click="() => jobsDialog(scope.row)">添加</el-button>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140" :resizable="false" v-if="[1, 2].includes(userInfo_role)">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="() => revision(scope.row)" v-if="[1].includes(userInfo_role)">编辑</el-button>
            <el-dropdown @command="putCustomerState" trigger="click" v-if="[2].includes(userInfo_role)">
              <el-button style="margin-left: 12px" type="text" size="mini">修改状态</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ customerId: scope.row.customerId, state: 0 }">未启动</el-dropdown-item>
                <el-dropdown-item :command="{ customerId: scope.row.customerId, state: 1 }">进行中</el-dropdown-item>
                <el-dropdown-item :command="{ customerId: scope.row.customerId, state: 2 }">已完成</el-dropdown-item>
                <el-dropdown-item :command="{ customerId: scope.row.customerId, state: 3 }">放弃</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getCompanyList" />
    </div>

    <add ref="add" @success="getCompanyList" />
    <jobsDialog ref="jobsDialog" @success="getCompanyList" />
    <imageDialog ref="imageDialog" />
    <trendsDialog ref="trendsDialog" />
  </div>
</template>
 <script>
// import CTag from "@/components/CTag"
import add from "@/views/CustomerManagement/components/add";
import jobsDialog from "@/views/CustomerManagement/components/jobsDialog";
import imageDialog from "@/views/CustomerManagement/components/imageDialog";
import trendsDialog from "@/views/CustomerManagement/components/trendsDialog";
import searchForm from "@/views/CustomerManagement/components/searchForm";
import { mapState } from "vuex";
export default {
  components: {
    // CTag,
    jobsDialog,
    add,
    searchForm,
    imageDialog,
    trendsDialog,
  },
  data () {
    return {
      list: [],
      listParams: {
        customerId: null,
        type: null,
        state: null,
        providerId: null,
        transformerId: null,
        keyword: "",
        orderBy: "",
        order: "",
      },
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.userInfo.user,
    }),
    trends () {
      return ({ trends }) => {
        if (trends.length) {
          return trends[trends.length - 1];
        } else {
          return {
            content: "",
          };
        }
      };
    },
    images () {
      return (detaiDatal) => {
        const images = detaiDatal.images || [];
        if (images.length) {
          return images
            .split(",")
            .map((item) => "https://oss.mcn-open.com/" + item);
        } else {
          return [];
        }
      };
    },
    userInfo_role () {
      return this.$store.state.userInfo.user.role;
    },
    userInfo_accountId () {
      return this.$store.state.userInfo.user.accountId;
    },
    isMine () {
      return (accountId) => {
        return this.userInfo_accountId == accountId;
      };
    },
  },
  mounted () {
    console.log(this.$route.params)
    this.listParams.customerId = this.$route.query.customerId;
    this.getCompanyList();
  },
  filters: {
    jobs (list) {
      return list.filter((res) => res.state < 1).length;
    },
  },
  methods: {
    tableRowClassName ({ row }) {
      if (row.customerId == this.listParams.customerId) {
        return "warning-row";
      }
      return "";
    },
    ajobs (list) {
      return list.filter((res) => res.state < 1).length;
    },
    sortChange ({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop;
      } else {
        this.listParams.order = "";
        this.listParams.orderBy = "";
      }
      this.getCompanyList();
    },
    async getCompanyList () {
      const { current, size } = this.$refs.pagination;
      const { listParams } = this;
      const { status, data } = await this.$api.getCustomerList({
        current,
        size,
        ...listParams,
      });
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data);
      }
    },
    add () {
      this.$refs.add.show({ type: "add" });
    },
    jobsDialog (data) {
      this.$refs.jobsDialog.show(data);
    },
    trendsDialog (data) {
      this.$refs.trendsDialog.show(data);
    },
    imageDialog (images, number) {
      this.$refs.imageDialog.show({ images, number });
    },
    revision (data) {
      this.$refs.add.show({
        data: {
          ...data,
          transformerId: data.transformer?.accountId,
        },

        type: "revision",
      });
    },
    async exportData () {
      const { status, info } = await this.$api.postExportCompany();
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.$store.commit("exportData/setBadge", true);
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
    async taxChange (res) {
      const { uuid, tax } = res;
      const { status, info } = await this.$api.putChangeCompany({
        uuid,
        tax: !tax,
      });
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.getCompanyList();
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
    async putCustomerState (data) {
      try {
        await this.$confirm(`此操作将修改该线索的状态, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        const { status, info } = await this.$api.putCustomerState(data);
        if (status === 200) {
          this.$message({
            type: "success",
            message: info,
          });
          this.getCompanyList();
        } else {
          this.$message({
            type: "error",
            message: info,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
 <style lang='scss' >
.el-table .warning-row {
  background: oldlace;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; /* 3行行高的大约高度 */
  line-height: 1.5em;
  white-space: normal;
}
.img {
  height: 3em;
}
.trends {
  background: rgb(254, 240, 240);
  color: #f56c6c;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 8px;
}
.yidu {
  background: rgb(236, 246, 255) !important;
  color: #409eff !important;
}
.jobs {
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  background: rgb(253, 246, 236);
  color: #e6a23c;
  border-radius: 50%;
  font-weight: 900;
}
</style>