/*
 * @Author: 曹俊杰 
 * @Date: 2024-08-03 11:50:25 
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-08-11 10:00:38
 */
<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :title="'编号:' + number + '       定时任务'"
      :visible.sync="dialogVisible"
      :size="670"
    >
      <div>
        <el-form
          style="padding: 0 20px"
          size="mini"
          label-position="top"
          label-width="120px"
          ref="form"
        >
          <div
            style="height: 500px; overflow-y: auto; padding: 20px 0"
            id="scrollable"
          >
            <el-empty
              v-if="jobs.length === 0"
              class="center-absolute"
            ></el-empty>
            <div
              class="box"
              v-for="item in jobs"
              :key="item.jobId"
              :class="{ boxweiwancheng: item.state == 0 }"
            >
              <p class="time">
                任务时间:{{ item.deadline | timeFormat("yyyy-mm-dd hh:MM") }}
              </p>
              <p class="content">{{ item.content }}</p>


              <div class="bottom"  v-if="[1,2].includes(userInfo_role)">
                <div class="wancheng" v-if="item.state == 1">
                  <span>已完成</span>
                  <i>
                    <span class="el-icon-check"></span>
                  </i>
                </div>
                <div
                  class="weiwancheng"
                  @click="() => putJobFinish(item)"
                  v-else-if="item.state == 0"
                >
                  <span>未完成</span>
                  <i></i>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              height: 80px;
              width: 100%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding: 0 24px;
            "
             v-if="[1, 2].includes(userInfo_role)"
          >
            <el-button
              type="primary"
              size="small"
              @click="submit"
             
              style="float: right"
              >添加</el-button
            >
          </div>
        </el-form>
      </div>
      <jobForm ref="jobForm" @success="success" />
    </el-dialog>
  </div>
</template>
<script>
import jobForm from "@/views/CustomerManagement/components/jobForm";
export default {
  components: {
    jobForm,
  },
  data() {
    return {
      // scaleList: [],
      dialogVisible: false,
      jobs: [],
      customerId: null,
      number: null,
    };
  },
  computed: {
    userInfo_role() {
      return this.$store.state.userInfo.user.role;
    },
  },
  mounted() {
    // this.getScaleList();
  },
  methods: {
    submit() {
      this.$refs.jobForm.show({
        type: "add",
        data: { customerId: this.customerId },
      });
    },
    success() {
      this.getCustomerDetail();
      this.$emit("success");
    },
    show({ customerId, number }) {
      this.customerId = customerId;
      this.getCustomerDetail();
      this.number = number;
      this.dialogVisible = true;
    },

    async getCustomerDetail(scroll = true) {
      let customerId = this.customerId;
      const {
        data: { jobs },
      } = await this.$api.getCustomerDetail({ customerId });
      this.jobs = jobs;
      scroll && this.scrollToBottom();
    },
    async putCompany() {
      // let { related } = this.form
      let api = {
        revision: {
          func: this.$api.putChangeCompany,
          params: {
            ...this.form,
            uuid: this.$route.params.uuid || this.form.uuid,
            // related: related.toString()
          },
        },
        add: {
          func: this.$api.putCompany,
          params: {
            ...this.form,
            // related: related.toString()
          },
        },
      };
      const { status, data, info } = await api[this.type].func(
        api[this.type].params
      );
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.dialogVisible = false;
        this.$emit("success");
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
    //完成
    async putJobFinish({ jobId }) {
      try {
        await this.$confirm("是否完成该任务？", "确认信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        });
        const { status, info } = await this.$api.putJobFinish({
          jobId,
        });
        if (status === 200) {
          this.$message({
            type: "success",
            message: info,
          });
          this.$emit("success");
          this.getCustomerDetail(false);
        } else {
          this.$message({
            type: "error",
            message: info,
          });
        }
      } catch (action) {
        console.log(action);
      }
    },
    closed() {
      this.customerId = null;
      this.number = null;
    },
    scrollToBottom() {
      function scrollToBottom(element) {
        element.scrollTop = element.scrollHeight - element.clientHeight;
      }
      this.$nextTick(() => {
        var scrollableElement = document.getElementById("scrollable");
        scrollToBottom(scrollableElement);
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.boxweiwancheng {
  background: rgb(254, 240, 240) !important;
}
.box {
  background: rgb(236, 245, 255);
  padding: 12px;
  border-radius: 8px;
  line-height: 2em;
  margin-bottom: 16px;
  .time {
    font-weight: 900;
  }
  .content {
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    line-height: 1em;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #e6a23c;
      color: #e6a23c;
      width: 1em;
      height: 1em;
      border-radius: 1em;
      font-weight: 900;
      text-align: center;
      margin-left: 10px;
    }
    .wancheng,
    .weiwancheng {
      display: flex;
    }
    .wancheng {
    }
    .weiwancheng {
    }
  }
}
.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
