/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-13 15:49:50
 * @Module: 状态
 */
 <template>
  <div>
    <el-select :value="value" placeholder="请选择线索状态" clearable @change="change" style="width:100%">
      <el-option v-for="item in [{
				value:0,
				label:'未启动'
			},
			{
				value:1,
				label:'进行中'
			},
			{
				value:2,
				label:'已完成'
			},{
				value:3,
				label:'放弃'
			}]" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>
 <script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>