/*
 * @Author: 曹俊杰 
 * @Date: 2024-08-03 11:50:25 
 * @Last Modified by: 
 * @Last Modified time: 2024-08-16 13:52:58
 */
<template>
  <div>
    <el-dialog :append-to-body="true" :title="'编号:' + number + '       销转进程'" :visible.sync="dialogVisible" :size="670">
      <div>
        <el-form style="padding: 0 20px" size="mini" label-position="top" label-width="120px" ref="form" :model="form">
          <div style="height: 500px; overflow-y: auto; padding: 20px 0" id="scrollable">
            <el-empty v-if="trends.length === 0" class="center-absolute"></el-empty>
            <div class="box-w" v-for="item in trends" :key="item.trendId" :class="{ fanzhuan: isMine(item.publisher.accountId) }">
              <p class="time">
                {{ item.publisher.name }}
                &nbsp;&nbsp;&nbsp;
                {{ item.updatedAt | timeFormat("yyyy-mm-dd hh:MM") }}
              </p>
              <div class="box" :class="{
                  boxweiwancheng:
                    !isMine(item.publisher.accountId) && !item.seen,
                }">
                <p class="content">{{ item.content }}</p>

                <div class="bottom" v-if="item.showSeen">

                  <div class="wancheng" v-if="item.seen">
                    <span>已查看</span>
                    <i>
                      <span class="el-icon-check"></span>
                    </i>
                  </div>

                  <div class="weiwancheng" @click="() => senBtn(item)" v-else>
                    <span>待查看</span>
                    <i></i>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <el-form-item prop="content" v-if="[1, 2].includes(userInfo_role)">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 12 }" v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item prop="content" v-if="[1, 2].includes(userInfo_role)">
            需对方完成<el-switch v-model="form.showSeen"></el-switch>
          </el-form-item>
          <div v-if="[1, 2].includes(userInfo_role)" style="
              height: 80px;
              width: 100%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
            <el-button type="primary" size="small" @click="submit" style="float: right">添加</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      // scaleList: [],
      dialogVisible: false,
      trends: [],

      number: null,
      form: {
        content: "",
        customerId: null,
        showSeen: false
      },
    };
  },
  mounted () {
    // this.getScaleList();
  },

  computed: {
    userInfo_role () {
      return this.$store.state.userInfo.user.role;
    },
    userInfo_accountId () {
      return this.$store.state.userInfo.user.accountId;
    },
    isMine () {
      return (accountId) => {
        return this.userInfo_accountId == accountId;
      };
    },
  },
  methods: {
    submit () {
      this.putCompany();
    },
    show ({ customerId, number }) {
      this.form.customerId = customerId;
      this.getCustomerDetail();
      this.number = number;
      this.dialogVisible = true;
    },

    async getCustomerDetail (scroll = true) {
      let customerId = this.form.customerId;
      const {
        data: { trends },
      } = await this.$api.getCustomerDetail({ customerId });
      this.trends = trends;
      scroll && this.scrollToBottom();
    },
    async putCompany () {
      const { status, data, info } = await this.$api.postTrend(this.form);
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.$emit("success");
        this.getCustomerDetail();
        this.form.content = "";
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
    senBtn (item) {
      if (this.userInfo_role !== 0) {
        if (item.publisher.accountId !== this.userInfo_accountId) {
          this.putTrendRead(item)
        }
      }
    },
    //完成
    async putTrendRead ({ trendId }) {
      try {
        await this.$confirm("是否改变进程状态？", "确认信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        });
        const { status, info } = await this.$api.putTrendRead({
          trendId,
        });
        if (status === 200) {
          this.$message({
            type: "success",
            message: info,
          });
          this.$emit("success");
          this.getCustomerDetail(false);
        } else {
          this.$message({
            type: "error",
            message: info,
          });
        }
      } catch (action) {
        console.log(action);
      }
    },
    closed () {
      this.form.customerId = null;
      this.number = null;
    },
    scrollToBottom () {
      function scrollToBottom (element) {
        element.scrollTop = element.scrollHeight - element.clientHeight;
      }
      this.$nextTick(() => {
        var scrollableElement = document.getElementById("scrollable");
        scrollToBottom(scrollableElement);
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.boxweiwancheng {
  background: rgb(254, 240, 240) !important;
}
.box-w {
  line-height: 3em;
  display: flex;
  flex-direction: column;
}
.fanzhuan {
  align-items: flex-end;
}
.box {
  background: rgb(236, 245, 255);
  padding: 12px;
  border-radius: 8px;
  line-height: 2em;
  margin-bottom: 16px;
  width: 50%;
  .time {
    font-weight: 900;
  }
  .content {
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    line-height: 1em;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #e6a23c;
      color: #e6a23c;
      width: 1em;
      height: 1em;
      border-radius: 1em;
      font-weight: 900;
      text-align: center;
      margin-left: 10px;
    }
    .wancheng,
    .weiwancheng {
      display: flex;
    }
    .wancheng {
    }
    .weiwancheng {
    }
  }
}
.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
