import request from '@/utils/request'

const api = {

	//添加报单
	postAddTask(params) {
		return request.post(`/api/task`, params)
	},
	//修改报单
	putEditTask(params) {
		return request.put(`/api/task/${params.taskId}`, params)
	},
	//撤回报单
	putWithdrawTask(params) {
		console.log(`/api/task/withdraw/${params.taskId}`)
		return request.put(`/api/task/withdraw/${params.taskId}`, params)
	},
	//重新提交报单
	putResubmitTask(params) {
		return request.put(`/api/task/resubmit/${params.taskId}`, params)
	},
	//审核报单
	putAuditTask(params) {
		return request.put(`/api/task/audit/${params.taskId}`, params)
	},
	//完成报单
	putFinishTask(params) {
		return request.put(`/api/task/finish/${params.taskId}`, params)
	},
	//报单列表
	getTaskList(params) {
		return request.get(`/api/task`, params)
	},
	//发起人列表
	getAccountPromoterList(params) {
		return request.get(` /api/account/promoter`, params)
	},
	//撤销完成报单
	putUnfinishedTask(params) {
		return request.put(`/api/task/unfinished/${params.taskId}`, params)
	},
	//帐号在线
	putAccountOnline(params) {
		return request.put(`/api/account/online`, params)
	},




	//添加账号
	putAccount(params) {
		return request.put(`/api/account`, params)
	},
	//修改账号
	putChangeAccount(params) {
		return request.put(`/api/account/${params.accountId}`, params)
	},
	//账号列表
	getAccountList(params) {
		return request.get(`/api/account`, params)
	},
	//登录
	postLogin(params) {
		return request.post(`/api/account/login`, params)
	},
	//获取登录验证码
	postSmsCodeLogin(params) {
		return request.post(`/api/sms/code/login`, params)
	},
	//公司列表
	getCompanyList(params) {
		return request.get(`/api/company`, params)
	},
	//创建公司
	putCompany(params) {
		return request.post(`/api/company`, params)
	},
	//修改公司
	putChangeCompany(params) {
		console.log(params)
		return request.put(`/api/company/${params.uuid}`, params)
	},
	//删除公司
	deleteCompany(params) {
		return request.delete(` /api/company/${params.uuid}`, params)
	},
	//公司详情
	getCompany(params) {
		return request.get(`/api/company/${params.uuid}`)
	},
	//公司检索
	getCompanySearch(params) {
		return request.get(`/api/company/search`, params)
	},
	//公司应收款项列表
	getReceivableList(params) {
		return request.get(`/api/receivable`, params)
	},
	//添加公司应收款项
	postReceivable(params) {
		return request.post(`/api/receivable`, params)
	},
	//删除公司应收款项
	deleteReceivable(params) {
		return request.delete(`/api/receivable/${params.uuid}`, params)
	},
	//修改公司应收款项
	putReceivable(params) {
		return request.put(`/api/receivable/${params.uuid}`, params)
	},
	//主营业务列表
	getBusinessList(params) {
		return request.get(`/api/business`, params)
	},
	//创建主营业务
	postBusiness(params) {
		return request.post(`/api/business`, params)
	},
	//删除主营业务
	deleteBusiness(params) {
		return request.delete(`/api/business/${params.businessId}`, params)
	},
	//法务帐号列表列表
	getAccountLegalList(params) {
		return request.get(`/api/account/legal`, params)
	},
	//分成帐号列表列表
	getAccountShareList(params) {
		return request.get(`/api/account/share`, params)
	},
	//平台列表
	getPlatformList(params) {
		return request.get(`/api/platform`, params)
	},
	//创建平台
	postPlatform(params) {
		return request.post(`/api/platform`, params)
	},
	//删除平台
	deletePlatform(params) {
		return request.delete(`/api/platform/${params.platformId}`, params)
	},

	//流水规模列表
	getScaleList(params) {
		return request.get(`/api/scale`, params)
	},
	//创建流水规模
	postScale(params) {
		return request.post(`/api/scale`, params)
	},
	//删除流水规模
	deleteScale(params) {
		return request.delete(` /api/scale/${params.scaleId}`, params)
	},
	//公司列表(关聅公司)
	getCompanySlaveList(params) {
		return request.get(`/api/company/slave`, params)
	},
	//创建公司(关聅公司)
	putCompanySlave(params) {
		return request.post(`/api/company/slave`, params)
	},
	//分成明细列表
	getPercentageList(params) {
		return request.get(`/api/percentage`, params)
	},
	//分成明细统计
	getPercentageProfile(params) {
		return request.get(`/api/percentage/profile`, params)
	},
	//创建分成明细
	postPercentage(params) {
		return request.post(`/api/percentage`, params)
	},
	//修改分成明细
	putChangePercentage(params) {
		return request.put(`/api/percentage/${params.uuid}`, params)
	},
	//删除分成明细
	deletePercentage(params) {
		return request.delete(`/api/percentage/${params.uuid}`, params)
	},
	//动态列表
	getTrendList(params) {
		return request.get(`/api/trend`, params)
	},
	//动态详情
	getTrendDetail(params) {
		return request.get(`/api/trend/${params.uuid}`, params)
	},
	// // 添加动态
	// postTrend(params) {
	// 	return request.post(`/api/trend`, params)
	// },
	// 区域列表
	getArea(params) {
		return request.get(`/api/area`, params)
	},
	//获取文件上传token
	getFileSts(params) {
		return request.get(`/api/file/sts`, params)
	},
	//公司数据统计(首页)
	getCompanyDashboard(params) {
		return request.get(`/api/company/dashboard`, params)
	},
	//外部公司数据统计(首页)
	getOuterCompanyDashboard(params) {
		return request.get(`/api/outer-company/dashboard`, params)
	},
	//案件数据统计(首页)
	getLawsuitDashboard(params) {
		return request.get(`/api/lawsuit/dashboard`, params)
	},
	//外部案件数据统计(首页)
	getOuterLawsuitDashboard(params) {
		return request.get(`/api/outer-lawsuit/dashboard`, params)
	},
	//辖区联络人
	getAccountRelated(params) {
		return request.get(`/api/account/related`, params)
	},
	//个案列表
	getIcaseList(params) {
		return request.get(`/api/icase`, params)
	},
	// 创建个案
	postIcase(params) {
		return request.post(`/api/icase`, params)
	},
	// 个案过滤器
	getIcaseFilter(params) {
		return request.get(`/api/icase/filter`, params)
	},
	// 修改个案
	putIcase(params) {
		return request.put(`/api/icase/${params.uuid}`, params)
	},
	// 删除个案手机验证码
	postDeleteIcase(params) {
		return request.post(`/api/sms/code/delete-icase`, params)
	},
	// 删除个案
	deleteIcase(params) {
		return request.delete(`/api/icase/${params.uuid}`, params)
	},

	//案件列表
	getLawsuit(params) {
		return request.get(`/api/lawsuit`, params)
	},
	//案件过滤器
	getLawsuitFilter(params) {
		return request.get(`/api/lawsuit/filter`, params)
	},
	// 创建案件
	postLawsuit(params) {
		return request.post(`/api/lawsuit`, params)
	},
	// 修改案件
	putChangeLawsuit(params) {
		return request.put(`/api/lawsuit/${params.lawsuitUuid}`, params)
	},
	// 删除案件
	deleteLawsuit(params) {
		return request.delete(`/api/lawsuit/${params.uuid}`, params)
	},
	//备选公司（添加案件）
	getCompanyAll(params) {
		return request.get(`/api/company/all`, params)
	},
	//修改案件进展
	putChangeLawsuitPhase(params) {
		return request.put(`/api/lawsuit-phase/${params.uuid}`, params)
	},
	//创建案件进展
	postLawsuitPhase(params) {
		return request.post(`/api/lawsuit-phase`, params)
	},
	//修改案件费用
	putChangeLawsuitCost(params) {
		return request.put(`/api/lawsuit-cost/${params.uuid}`, params)
	},
	//创建案件赔付
	postLawsuitPayment(params) {
		return request.post(`/api/lawsuit-payment`, params)
	},
	//修改案件赔付
	putChangeLawsuitPayment(params) {
		return request.put(`/api/lawsuit-payment/${params.uuid}`, params)
	},
	//删除案件赔付
	deleteLawsuitPayment(params) {
		return request.delete(`/api/lawsuit-payment/${params.uuid}`, params)
	},

	//创建案件权益
	postLawsuitEquity(params) {
		return request.post(`/api/lawsuit-equity`, params)
	},
	//修改案件权益
	putChangeLawsuitEquity(params) {
		return request.put(`/api/lawsuit-equity/${params.uuid}`, params)
	},
	//删除案件权益
	deleteLawsuitEquity(params) {
		return request.delete(`/api/lawsuit-equity/${params.uuid}`, params)
	},

	//创建判决项
	postJudgmentItem(params) {
		return request.post(`/api/judgment-item`, params)
	},
	//修改判决项
	putChangeJudgmentItem(params) {
		return request.put(`/api/judgment-item/${params.itemId}`, params)
	},
	//删除判决项
	deleteJudgmentItem(params) {
		return request.delete(`/api/judgment-item/${params.itemId}`, params)
	},


	//预存款列表
	getDepositList(params) {
		return request.get(`/api/deposit`, params)
	},
	//创建预存款
	postDeposit(params) {
		return request.post(`/api/deposit`, params)
	},
	//修改预存款
	putChangeDeposit(params) {
		return request.put(`/api/deposit/${params.uuid}`, params)
	},
	//删除预存款
	deleteDeposit(params) {
		return request.delete(`/api/deposit/${params.uuid}`, params)
	},
	//创建外部公司
	postOuterCompany(params) {
		return request.post(`/api/outer-company`, params)
	},
	//修改外部公司
	putChangeOuterCompany(params) {
		console.log(params)
		return request.put(`/api/outer-company/${params.uuid}`, params)
	},
	//删除外部公司
	deleteOuterCompany(params) {
		return request.delete(`/api/outer-company/${params.uuid}`, params)
	},
	//外部公司列表
	getOuterCompanyList(params) {
		return request.get(`/api/outer-company`, params)
	},
	//外部公司备选公司（添加案件）
	getOuterCompanyAllList(params) {
		return request.get(`/api/outer-company/all`, params)
	},
	//外部公司过滤器
	getOuterCompanyFilter(params) {
		return request.get(`/api/outer-company/filter`, params)
	},

	//创建外部缴费
	postOuterPayment(params) {
		return request.post(`/api/outer-payment`, params)
	},
	//修改外部缴费
	putChangeOuterPayment(params) {
		return request.put(`/api/outer-payment/${params.uuid}`, params)
	},
	//删除外部缴费
	deleteOuterPayment(params) {
		return request.delete(`/api/outer-payment/${params.uuid}`, params)
	},
	//外部缴费列表
	getOuterPaymentList(params) {
		return request.get(`/api/outer-payment`, params)
	},


	//外部公司详情
	getOuterCompanyDetail(params) {
		return request.get(`/api/outer-company/${params.uuid}`)
	},


	//创建外部案件
	postOuterLawsuit(params) {
		return request.post(`/api/outer-lawsuit`, params)
	},
	//修改外部案件
	putChangeOuterLawsuit(params) {
		return request.put(`/api/outer-lawsuit/${params.uuid}`, params)
	},
	//外部案件列表
	getOuterLawsuitList(params) {
		return request.get(`/api/outer-lawsuit`, params)
	},
	//外部案件过滤器
	getOuterLawsuitFilter() {
		return request.get(`/api/outer-lawsuit/filter`)
	},
	//修改外部案件费用
	putChangeOuterLawsuitCost(params) {
		return request.put(`/api/outer-lawsuit-cost/${params.uuid}`, params)
	},
	//修改外部案件进展
	putChangeOuterLawsuitPhase(params) {
		return request.put(`/api/outer-lawsuit-phase/${params.uuid}`, params)
	},
	//创建外部案件进展
	postOuterLawsuitPhase(params) {
		return request.post(`/api/outer-lawsuit-phase`, params)
	},
	//创建提现申请
	postWithdrawApplication(params) {
		return request.post(`/api/withdraw-application`, params)
	},
	//提现申请列表
	getWithdrawApplicationList(params) {
		return request.get(`/api/withdraw-application`, params)
	},
	//撤消提现申请
	putPercentageCancel(params) {
		return request.put(`/api/withdraw-application/cancel`, params)
	},
	//审批提现申请
	putPercentageProcess(params) {
		return request.put(`/api/withdraw-application/process`, params)
	},

	//联盟会员列表
	getPromoterList(params) {
		return request.get(`/api/promoter`, params)
	},
	//修改会员
	putPromoter(params) {
		return request.put(`/api/promoter`, params)
	},
	//创建会员
	postPromoter(params) {
		return request.post(`/api/promoter`, params)
	},
	//联盟会员树列表
	getPromoterTreeList(params) {
		return request.get(`/api/promoter/tree`, params)
	},
	//会员列表下拉菜单
	getPromoterCandidateList(params) {
		return request.get(`/api/promoter/candidate`, params)
	},

	//创建会员来源
	postSource(params) {
		return request.post(`/api/source`, params)
	},
	//会员来源列表
	getSourceList(params) {
		return request.get(`/api/source`, params)
	},
	//删除会员来源
	deleteSource(params) {
		return request.delete(`/api/source/${params.uuid}`)
	},

	//成单记录列表
	getDealList(params) {
		return request.get(`/api/deal`, params)
	},
	//修改成单记录
	putDeal(params) {
		return request.put(`/api/deal`, params)
	},
	//创建成单记录
	postDeal(params) {
		return request.post(`/api/deal`, params)
	},
	//成单下拉菜单
	getDealCandidateList(params) {
		return request.get(`/api/deal/candidate`, params)
	},

	//分佣列表
	getCommissionList(params) {
		return request.get(`/api/commission`, params)
	},
	//修改分佣
	putCommission(params) {
		return request.put(`/api/commission`, params)
	},
	//创建分佣
	postCommission(params) {
		return request.post(`/api/commission`, params)
	},
	//删除分佣
	deleteCommission(params) {
		return request.delete(`/api/commission/${params.uuid}`)
	},
	//分佣统计
	getCommissionProfile(params) {
		return request.get(`/api/commission/profile`, params)
	},

	//创建分佣提现申请
	postWithdrawApp(params) {
		return request.post(`/api/withdraw-app`, params)
	},
	//分佣提现申请列表
	getWithdrawAppList(params) {
		return request.get(`/api/withdraw-app`, params)
	},
	//撤消分佣提现申请
	putWithdrawAppCancel(params) {
		return request.put(` /api/withdraw-app/cancel`, params)
	},
	//审批分佣提现申请
	putWithdrawApp(params) {
		return request.put(`/api/withdraw-app/process`, params)
	},
	//导出内部案件
	postExportLawsuit(params) {
		return request.post(`/api/export/lawsuit`, params)
	},
	//导出线索管理
	postExportCompany(params) {
		return request.post(`/api/export/company`, params)
	},
	//导出外部案件
	postExportOuterLawsuit(params) {
		return request.post(`/api/export/outer-lawsuit`, params)
	},
	//导出外部客户
	postExportOuterCompany(params) {
		return request.post(`/api/export/outer-company`, params)
	},
	//导出报单列表
	getExportList(params) {
		return request.get(`/api/export`, params)
	},
	//意向客户列表
	getClientList(params) {
		return request.get(`/api/client`, params)
	},
	//创建意向客户
	postClient(params) {
		return request.post(`/api/client`, params)
	},
	//意向客户查重
	getClientSearch(params) {
		return request.get(`/api/client/search`, params)
	},
	//法务团队列表
	getTeamList(params) {
		return request.get(` /api/team`, params)
	},






	// 客户
	//添加客户
	postCustomer(params) {
		return request.post(`/api/customer`, params)
	},
	//修改客户
	putCustomer(params) {
		return request.put(`/api/customer/${params.customerId}`, params)
	},
	//完成销转客户
	putCustomerFinish(params) {
		return request.put(`/api/customer/finish/${params.customerId}`, params)
	},
	//客户列表
	getCustomerList(params) {
		return request.get(`/api/customer`, params)
	},
	//客户筛选列表
	getCustomerFilterList(params) {
		return request.get(`/api/customer/filter`, params)
	},
	//客户详情
	getCustomerDetail(params) {
		return request.get(`/api/customer/${params.customerId}`, params)
	},
	//未完成的定时任务
	getJobInit(params) {
		return request.get(`/api/job/init`, params)
	},
	//未完成的进程
	getTrendUnseen(params) {
		return request.get(`/api/trend/unseen`, params)
	},
	//添加定时任务
	postjob(params) {
		return request.post(`/api/job`, params)
	},
	//完成定时任务
	putJobFinish(params) {
		return request.put(`/api/job/finish/${params.jobId}`, params)
	},
	//修改客户状态
	putCustomerState(params) {
		return request.put(`/api/customer/state/${params.customerId}`, params)
	},
	//添加进程
	postTrend(params) {
		return request.post(`/api/trend`, params)
	},
	//完成进程
	putTrendRead(params) {
		return request.put(`/api/trend/read/${params.trendId}`, params)
	},

}

export default api