/*
 * @Author: 曹俊杰 
 * @Date: 2024-08-03 11:50:25 
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-08-03 14:02:40
 */
<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :title="'编号:' + number + '       聊天截图'"
      :visible.sync="dialogVisible"
      width="800px"
      height="500px"
    >
      <div
        style="height: 500px; width: 800px; overflow-y: scroll; padding: 20px"
        id="scrollable"
      >
        <el-row>
          <el-col :span="4" v-for="(item, index) in images" :key="index" style="margin-bottom:8px;">
            <el-image
              style="width: 100px; height: 200px"
              fit="cover"
              :src="item"
              :preview-src-list="images"
            >
            </el-image>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      images: [],
      number: null,
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    show({ images, number }) {
      this.images = images;
      this.number = number;
      this.dialogVisible = true;
    },
    closed() {
      this.customerId = null;
      this.number = null;
    },
  },
};
</script>
<style lang='scss' scoped>
</style>
