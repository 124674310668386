<template>
  <div class="jobitem" :class="color">
    <p>编号：{{ item.customer.number }}</p>
    <p>
      任务时间：{{
                  item.deadline | timeFormat("yyyy-mm-dd hh:MM:ss")
                }}
    </p>
    <div class="daojishi">
      <p style="color: rgb(252, 202, 0)">倒计时：</p>
      <el-statistic @change="change" format="DD天HH小时mm分钟ss秒" :value="item.deadline" time-indices :inherit="{ color: 'inherit ' }">
      </el-statistic>
    </div>

    <div class="btn pointer" @click="()=>toList(item)">查看</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({
        deadline: null,
        customer: {
          number: null,
          customerId: null
        }
      })
    }
  },
  data () {
    return {
      count: 0,
      color: 'red'
    };
  },
  mounted () { },
  methods: {
    toList ({ customer: { customerId } }) {
      this.$router.push({ path: "/customerManagement", query: { customerId } });
    },
    change (value) {
      // console.log(value);
      this.count = value;
      if (value <= (1000 * 60 * 15)) {
        this.color = "yellow"
      } else if (value > (1000 * 60 * 15)) {
        this.color = "green"
      } else if (value < 1000) {
        this.color = "red"
      }
    }
  },
};
</script>
<style lang='scss' scoped>
.daojishi {
  display: flex;
  align-items: center;
  /deep/.el-statistic {
    width: auto;
    .con {
      color: rgb(252, 202, 0);
      .number {
        font-size: 16px;
      }
    }
  }
}
.green {
  background: rgb(240, 249, 235);
	color: #67C23A;
}
.yellow {
  background: rgb(253, 246, 236);
	color: #E6A23C;
}
.red {
  background: rgb(254, 240, 240);
	color: #F56C6C;
}
.jobitem {
  // background: rgb(127, 131, 247);
  position: relative;
  padding: 16px;
  // color: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
  p {
    font-size: 16px;
    line-height: 1.5em;
  }
  .btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>